import axios from "axios";
import {getUrl} from"./URl/Url";

//Post Requests
//------------------------------
// Process payment threeds
export const processPaymentThreedsApi = async (userData) => {
  let userRes= JSON.parse(sessionStorage.getItem("otpResponse"));
  let userType= parseInt(userRes.user_type);
  if(userType !=2){
    try {
      let processPaymentThreedsResponse = await axios({
        // Endpoint to send files
        url : getUrl()+"/customerOrders/process_payment_threeds",
        headers : { 
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method  : "POST",
        data    : userData, // Attaching the form data
      });
      // sessionStorage.setItem("isRedirect",0);
      //setItem storing the data in session
      return processPaymentThreedsResponse;
      
    } catch (e) {
      console.log(e);
    }
  }
  
};

export const recordTransactionDetailsApi = async (userData) => {
  try {
    let recordTransactionDetailsResponse = await axios({
      // Endpoint to send files
      url     : getUrl()+"/customerOrders/record_transaction_details",
      headers : { 
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method  : "POST",
      data    : userData, // Attaching the form data
    });
    // sessionStorage.setItem("isRedirect",0);
    //setItem storing the data in session
    return recordTransactionDetailsResponse;
    
  } catch (e) {
    console.log(e);
  }
};

//------------------Contact Us API--------------------------------
//---------------------------------------------------------
export const dynamicTimeSlotsApi = async (userData) => {
  try {
    let dynamicTimeSlotsResponse = await axios({
      // Endpoint to send files
      url: getUrl() + `/restaurants/get_dynamic_timeslots?rest_id=${userData.rest_id}&date_timestamp=${userData.date_timestamp}&is_self_pickup=${userData.is_self_pickup}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },

      method: "GET",
      data: userData,
    });
    return dynamicTimeSlotsResponse;
  } catch (e) {
    console.log(e);
  }
};
