import { React, useEffect, useState } from "react";
import { TermsAndConditionApi } from "../../../../services/ProfilePageServices";
import LandingHeader from "../../Landing_header/Landingheader";

function PrivacyPolicy() {
  const [termsData, setTermsData] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const termsConditionValuesApi = async () => {
      let postTermsConditionObject = {
        page_key: "privacypolicy",
        app_id: "2",
      };

      try {
        let TermsAndConditionResponse = await TermsAndConditionApi(
          postTermsConditionObject
        );
        setTermsData(TermsAndConditionResponse.data.data);
      } catch (e) {}
      finally{
        setLoader(false);

      }
    };
    termsConditionValuesApi();
  }, []);

  return (
    <>
    <LandingHeader/>
    {loader ? (
        <p className="TermsConditionLoader">Loading Please Wait...</p>
      ) : (
      <div className="TermsConditionContainer">
        <div className="title-container ">
          <h2 className="mt-5">{termsData?.page_name}</h2>
        </div>
        <div
          className="term-condition"
          dangerouslySetInnerHTML={{ __html: termsData?.page_value }}
        ></div>
      </div>)}
    </>
  );
}

export default PrivacyPolicy;
