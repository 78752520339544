import { React, useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import DesiEatsImage from "../Items/DesiEatsImage";
import ToggleMenuButton from "../Items/ToggleMenuButton";
import Forms from "../Form/Forms";
import {
  deliverLatLngData,
  getName,
  getUserData,
  getUserType,
  sessionLocationData,
} from "../../../constants/Utils";
import "./Landingheader.css";
import { json, useLocation, useNavigate } from "react-router-dom";
import Location from "../Location/Location";
import { FaUserAlt } from "react-icons/fa";
import { Button, FormSelect } from "react-bootstrap";
import { ImLocation } from "react-icons/im";
import { AllAddressApi } from "../../../services/ProfilePageServices";
import { AiTwotoneHome } from "react-icons/ai";
import UserProfileButton from "./UserProfileButton";
import { useSelector, useDispatch } from "react-redux";

import { HiOfficeBuilding } from "react-icons/hi";
import Geocode from "react-geocode";
import { logOutApi } from "../../../services/Landingservice";
import { addressToggleModeDataMethod } from "../../../containers/app/features/CounterSlice";
Geocode.setApiKey("AIzaSyAZNYje65H5kEiuMuF_gFmDwloZLmuIv-I");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

function LandingHeader({
  checkoutPostDetailsValuesApi,
  itemIdState,
  foodIdState,
  productListingValuesApi,
}) {
  const [allAddressData, setAllAddressData] = useState([]);
  const [activeAddress, setActiveAddress] = useState();
  const [addressData, setAddressData] = useState();

  const [addressChangeData, setAddressChangeData] = useState({});

  const currentLatLng = (currntLatLng) => {
    setAddressChangeData(currntLatLng);
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const AllAddressDataApi = async () => {
    let postAllAddressObj = {
      page: "0",
      limit: "100",
    };

    try {
      let allAddressApidataResponse = await AllAddressApi(postAllAddressObj);
      let result = allAddressApidataResponse.data.data;
      setAllAddressData(result);
      if (result && result.length > 0) {
        Geocode.fromAddress(result[0].pin_address).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            // sessionStorage.setItem(
            //   "deliverLatLng",
            //   JSON.stringify({
            //     lat,
            //     lng,
            //     pin_address: result[0].pin_address,
            //     unit_number: result[0].unit_number,
            //     street_address: result[0].street_address,
            //     postal_code: result[0].postal_code,
            //   })
            // );
            // sessionStorage.setItem(
            //   "deliveryAddress",
            //   ` ${
            //     result[0].label_type === "1"
            //       ? "Home  "
            //       : result[0].label_type === "2"
            //       ? "Office  "
            //       : result[0].label_type === "3"
            //       ? "Others  "
            //       : null
            //   } ${result[0].unit_number}, ${result[0].street_address},
            // ${result[0].pin_address}, ${result[0].postal_code}`
            // );
          },
          (error) => {
            console.error(error);
          }
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (sessionStorage.getItem("userLocation")) {
      AllAddressDataApi();

      setAddressData(
        JSON.parse(sessionStorage.getItem("userLocation")).pin_address
      );
    }
  }, []);

  const data = window.sessionStorage.getItem("deliverLatLng");

  let RestUrl = sessionStorage.getItem("identifier_key")
    ? sessionStorage.getItem("identifier_key")
    : null;

  const handleAddressOnChange = (e) => {
    let value = e.target.value;
    if (value) {
      allAddressData?.map((address) => {
        if (e.target.value.includes(address.pin_address)) {
          setAddressData(address.pin_address);
          currentLatLng(address);
          AllAddressApi();
          sessionStorage.setItem("userLocation", JSON.stringify(address));
          Geocode.fromAddress(e.target.value).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              
              sessionStorage.setItem(
                "deliverLatLng",
                JSON.stringify({
                  lat,
                  lng,
                  unit_number: address?.unit_number,
                  street_address: address?.street_address,
                  pin_address: address?.pin_address,
                  postal_code: address?.postal_code,
                  label_type: address?.label_type,
                  id: address?.id,
                })
              );

             
            },
            (error) => {
              console.error(error);
            }
          );
        }
      });
      if (value.includes("Home")) {
        setActiveAddress("Home");
     
      } else if (value.includes("Office")) {
        setActiveAddress("Office");
      
      } else if (value.includes("Others")) {
        setActiveAddress("Others");
     
      } else {
        setActiveAddress("Others");
      }
    }
    setTimeout(() => {
      handleCheckout();
    }, 1000);
  };
  let navigate = useNavigate();
  const ClearSession = () => {
    sessionStorage.clear();
    // sessionStorage.removeItem()  - removes particular element
  };
  const logOutButtonApi = async () => {
    let postObject = {
      device_id: "",
      device_type: "",
      device_token: "",
      is_deactivate: "0",
    };
    try {
      let LogoutButtonResponse = await logOutApi(postObject);
      if (LogoutButtonResponse.status === 200) {
        navigate("/");
        ClearSession();
        window.location.reload(true);
      }
    } catch (e) {}
  };
  dispatch(addressToggleModeDataMethod(addressData));
  // useEffect(() => {

  // }, [addressData]);
  let unitNumber = deliverLatLngData()?.unit_number
    ? deliverLatLngData()?.unit_number
    : sessionLocationData()?.unit_number;
  let streetAddress = deliverLatLngData()?.street_address
    ? deliverLatLngData()?.street_address
    : sessionLocationData()?.street_address;
  let pinAddress = deliverLatLngData()?.pin_address
    ? deliverLatLngData()?.pin_address
    : sessionLocationData()?.pin_address;
  let postalCode = deliverLatLngData()?.postal_code
    ? deliverLatLngData()?.postal_code
    : sessionLocationData()?.postal_code;
  let deliveryAddressPayment = pinAddress;

  sessionStorage.setItem(
    "deliveryAddressPay",
    JSON.stringify(deliveryAddressPayment)
  );
  // if(location.pathname!=="/"){
  //   window.initHippo({
  //     appSecretKey: "804f8e693c07559e9a03db0721f711e2",
  //     hideNewConversation: "1",
  //     alwaysSkipBot: true,
  //     language: "en",
  //     color: "#FF6838",
  //     email: getUserData()?.email,
  //     name: getUserData()?.fullname,
  //     phone: getUserData()?.mobile,
  //     customAttributes: {
  //       appId: "2",
  //     },
  //   });
  // }
 

  const handleCheckout = () => {
    AllAddressDataApi();
    if (location.pathname === `/restaurants/${RestUrl}`) {
      productListingValuesApi();
    }
    if (
      location.pathname === `/restaurants/${RestUrl}` ||
      location.pathname == "/payment"
    ) {
      checkoutPostDetailsValuesApi();
    }
  };

  let LocationForms = sessionStorage.getItem("userLocation")
    ? Object.keys(JSON.parse(sessionStorage.getItem("userLocation")))?.length
    : 0;
  return (
    <>
      <section>
        <div className="header-container">
          <div className="image-container">
            <DesiEatsImage />
          </div>
          {/* // ) ? ( // <>// </>
          // )
          */}

          {(sessionStorage.getItem("isDirectLanding")
            ? JSON.parse(sessionStorage.getItem("isDirectLanding"))
              ? JSON.parse(sessionStorage.getItem("isDirectLanding")) == 1
              : null
            : null) &&
          (sessionStorage.getItem("userLocation")
            ? Object.keys(JSON.parse(sessionStorage.getItem("userLocation")))
                ?.length == 0
            : null) ? null : (
            <>
              {location.pathname !== "/" &&
              location.pathname !== "/terms-condition"&&location.pathname !== "/privacy-policy" ? (
                <>
                  <div className="adress-container">
                    <div className="data-content">
                      <div className="adress-content">
                        <small className="DeliveryTo ">Delivering To</small>
                      </div>
                      <div className="adress-content">
                        {activeAddress === "Home" ? (
                          <AiTwotoneHome className="LocationIconHeader ms-3 mt-1" />
                        ) : activeAddress === "Office" ? (
                          <HiOfficeBuilding className="LocationIconHeader ms-3 mt-1" />
                        ) : (
                          <ImLocation className="LocationIconHeader ms-3 mt-1" />
                        )}
                      </div>
                      <div className="adress-content">
                        <FormSelect
                          className="address_view_data"
                          onChange={(e) => {
                            handleAddressOnChange(e);
                          }}
                          value={addressData}
                        >
                          {allAddressData?.map((item) => (
                            <option key={item.id} value={item.pin_address}>
                              <div className="adressTypeClass">
                                {/* <small className="adressTypeClass"> */}
                                {item.label_type === "1"
                                  ? "Home  "
                                  : item.label_type === "2"
                                  ? "Office  "
                                  : item.label_type === "3"
                                  ? "Others  "
                                  : null}
                                {/* </small> */}
                              </div>
                              <small className=" mt-4">
                                {item.unit_number
                                  ? item.unit_number + ","
                                  : null}
                                {item.street_address
                                  ? item.street_address + ","
                                  : null}
                                {item.pin_address
                                  ? item.pin_address + ","
                                  : null}
                                {getUserType !== 2
                                  ? item.postal_code
                                    ? item.postal_code + ","
                                    : null
                                  : item.postal_code
                                  ? item.postal_code !== "546080"
                                    ? item.postal_code
                                    : "546080"
                                  : null}
                              </small>
                            </option>
                          ))}
                        </FormSelect>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
          {location.pathname !== "/terms-condition"&&location.pathname !== "/privacy-policy" ? (
            <div
              className={
                location.pathname !== "/"
                  ? "menu-container"
                  : "menu-container toggleSpaceHome"
              }
            >
              {getUserType() !== 2 &&
                getUserType() !== null &&
                LocationForms != 0 && (
                  <ToggleMenuButton
                    itemIdState={itemIdState}
                    foodIdState={foodIdState}
                  />
                )}
            </div>
          ) : null}

          {location.pathname !== "/terms-condition"&&location.pathname !== "/privacy-policy" ? (
            <>
              {location.pathname !== "/profile" ? (
                <UserProfileButton />
              ) : (
                <div className="user-container">
                  <button
                    className="sign logOutButton"
                    //  className="LogOutbutton_profile mt-3"
                    onClick={() => {
                      logOutButtonApi();
                      ClearSession();
                      navigate("/");
                    }}
                  >
                    <p className="LogOutButtonText">Log Out</p>
                  </button>
                </div>
              )}
            </>
          ) : null}
        </div>
      </section>
    </>
  );
}

export default LandingHeader;
