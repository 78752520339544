
export const getUrl = () => {
    // let Apiurl = "http://139.59.184.252";
    //live URL
    let Apiurl = "https://ifdapi.mr-merchant.com";

    //TEST URL
    //let Apiurl = "https://ifdapi.onedigitaldemo.com";
    return Apiurl
}


