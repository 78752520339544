import { React, useState, useEffect } from "react";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import { useSelector, useDispatch } from "react-redux";
import "./Location.css";
import { useFormik } from "formik";
import { addressId } from "../../../constants/Utils";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { getUserType } from "../../../constants/Utils";
import {
  addressdatas,
  tabvaluedata,
  langDataMethod,
  latDataMethod,
} from "../../../containers/app/features/CounterSlice";
import { addressValuesSession } from "../../../constants/Utils";
import {
  LocationPopupApi,
  postalcodeAPI,
} from "../../../services/Landingservice";
import { AllAddressApi } from "../../../services/ProfilePageServices";

function Location({ resetForm, LocationPopUp, showLocation, tabValueData }) {
  const AddressDatas = useSelector((state) => state.counter.addressdatavalues);
  const tabval = useSelector((state) => state.counter.tabvalue);
  const latval = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const CloseLocationPopUp = () => {
    LocationPopUp(false); //callback function
  };

  Geocode.setApiKey("AIzaSyAZNYje65H5kEiuMuF_gFmDwloZLmuIv-I");

  Geocode.setLanguage("en");

  Geocode.setRegion("es");

  Geocode.setLocationType("ROOFTOP");

  Geocode.enableDebug();

  const [value, setValue] = useState(null);
  const [latLong, setLatLong] = useState();

  function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }

  useEffect(() => {
    if (value && value.label) {
      Geocode.fromAddress(value.label).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setLatLong({ lat, lng });
          postalcodeAPI(lat, lng).then((response) => {
            let postalCodeResp =
              response.data.results[0].address_components[
                response.data.results[0].address_components.length - 1
              ].long_name;
            formik.setFieldValue(
              "postal_code",
              containsOnlyNumbers(postalCodeResp) ? postalCodeResp : ""
            );
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [value]);
  const [tabValue, setTabValue] = useState("");
  const handleClicked = (value) => {
    setTabValue(value);
    dispatch(addressdatas(formik.values));
    dispatch(tabvaluedata(tabValue));
    dispatch(latDataMethod(latLong));
  };

  let navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/home");
  };

  const [handleConfirm, setHandleConfirm] = useState(false);

  const getLocationPopupApi = async () => {
    setHandleConfirm(true);
    
    let list = { Home: "1", Office: "2", Other: "3" };
    let locationPopUpObject = {
      latitude: latLong.lat,
      longitude: latLong.lng,
      pin_address: value?.label,
      unit_number: getUserType() !== 2 ? formik.values.UnitNumber : "",
      street_address: getUserType() !== 2 ? formik.values.house : "",
      postal_code:
        getUserType() !== 2
          ? formik.values.postal_code
          : formik.values.postal_code
          ? formik.values.postal_code
          : "546080",
      address_type: getUserType() !== 2 ? list[tabValue] : "3",
    };
    
    try {
      console.log(getUserType());
      console.log(Object.keys(formik.errors));
      if (
        getUserType() !== 2
          ? Object.keys(formik.errors).length === 0 &&
            Object.keys(formik.touched).length !== 0 &&
            tabValue !== ""
          : value !== null
      ) {
        let LocationApiResponse = await LocationPopupApi(locationPopUpObject);
        sessionStorage.setItem(
          "addressType",
          LocationApiResponse?.data?.data?.address_type
        );

        if (addressId === null || addressValuesSession?.address_id !== "0") {
          sessionStorage.setItem("isFirstTimeOpenPopup", true);
        }

        if (LocationApiResponse.status === 200) {
          let RestUrl = sessionStorage.getItem("identifier_key")
            ? sessionStorage.getItem("identifier_key")
            : null;
          if (window.location.href.includes(`/restaurants/${RestUrl}`))
            window.location.reload(true);
          if (
            window.location.href.includes("/home") ||
            window.location.href.includes(`/restaurants/${RestUrl}`)
          ) {
            CloseLocationPopUp();
          } else if (window.location.href.includes("/payment")) {
            CloseLocationPopUp();
            window.location.reload(true);
          } else {
            navigateToHome();
          }
        }
      }else{
        CloseLocationPopUp();
      }
    } catch (e) {
     
    }
  };
  const [markusError, setMarkUsError] = useState();
  const showmarkUsError = () => {
    if (tabValue === "") {
      setMarkUsError("Please Select Address Type!");
    } else {
      setMarkUsError();
    }
  };

  const initialValues = {
    addressLine: "",
    house: "",
    UnitNumber: "",
    postal_code: "",
  };
  const onSubmit = (values) => {
    //Pass data through state in useNavigation
    // if (values !== null) {
    //   navigate("/home", {
    //     state: {
    //       addressLine: formik.values.addressLine,
    //       postal_code: formik.values.postal_code,
    //     },
    //   });
    // }
    //}
  };

  const validationSchema = yup.object({
    house: yup.string().required("Enter Your house"),
    UnitNumber: yup.string().required("Enter Your Unit Number"),

    postal_code: yup
      .string()
      .required("Enter Your Postal Code")
      .matches(/^[0-9\b]+$/, "Please Enter Digits Only")
      .min(6, "Enter 6 digits postal_code"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        addressLine: "",
        house: "",
        UnitNumber: "",
        postal_code: "",
      },
    });
  }, [resetForm]);
  const handleBlur = () => {
    if (tabValue !== "") {
      setMarkUsError();
    } else {
      setMarkUsError("Please Select Address Type!");
    }
  };

 
  // const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyCbvsmGKcJvrIoY8AhUgKLYfPumFBI4TKk";

  // const API_KEY = "AIzaSyCbvsmGKcJvrIoY8AhUgKLYfPumFBI4TKk";
  //  var x =
  //  document.getElementById(
  //    "users").length;
 
  // const [locationState,setLocationState]=useState()
  // const locationResponseDatas=(value)=>{
  //   setLocationState()
  // }

  return (
    <>
      <Modal
        className="locationPopupModal"
        show={showLocation}
        onHide={CloseLocationPopUp}
        animation={true}
      >
        <Modal.Header className="locationPopUp_Header border-0" closeButton>
          <Col lg="10">
            <Modal.Title className="ms-2 mt-1">
              <b>Choose location</b>
            </Modal.Title>
            <hr width="80%" className="ms-2"></hr>
          </Col>
        </Modal.Header>
        <Form
          onSubmit={formik.handleSubmit}
          id="users"
          className="locationPopupForm"
        >
          <Modal.Body>
            <Container>
              <Row>
                <div className="input-style">
                  <Form.Label>
                    {" "}
                    {getUserType() !== 2 && getUserType() !== null
                      ? "Address Line 1"
                      : "Enter your delivery address "}
                  </Form.Label>
                  <span className="gogleFormCntnr">
                    <GooglePlacesAutocomplete
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["sg"],
                        },
                      }}
                      apiKey="AIzaSyAZNYje65H5kEiuMuF_gFmDwloZLmuIv-I"
                      apiOptions={{ language: "en" }}
                      selectProps={{
                        blurInputOnSelect: true,
                        value,
                        onFocus: () => setValue(null),
                        placeholder: "Enter delivery address",
                        onChange: setValue,
                        isSearchable: true,
                        components: "country:us",
                        className: "customSelectClass",
                        noOptionsMessage: ({ inputValue }) =>
                          !inputValue ? "No results found" : "No results found",
                      }}
                    />
                  </span>
                </div>
                {handleConfirm == true && value?.label == undefined ? (
                  <p className="DeliveryAddressError">
                    Please enter a delivery address
                  </p>
                ) : null}
                {getUserType() !== 2 && getUserType() !== null ? (
                  <>
                    <div className="input-style">
                      <Form.Label>BLK/House/Apartment No</Form.Label>
                      <Form.Control
                        name="house"
                        id="house"
                        className="form-control inputLocation2 mt-2 mb-2 "
                        placeholder="Enter Your BLK/House/Apartment No"
                        {...formik.getFieldProps("house")}
                      ></Form.Control>
                      {formik.touched.house && formik.errors.house && (
                        <div className="mb-2 warning" style={{ color: "red" }}>
                          {formik.errors.house}
                        </div>
                      )}
                    </div>
                    <div className="input-style">
                      <Form.Label>Unit Number</Form.Label>
                      <Form.Control
                        name="UnitNumber"
                        id="UnitNumber"
                        className="form-control inputLocation3 mt-2 mb-2"
                        placeholder="Enter Your Unit Number"
                        {...formik.getFieldProps("UnitNumber")}
                      ></Form.Control>
                      {formik.touched.UnitNumber &&
                        formik.errors.UnitNumber && (
                          <div
                            className="mb-2 warning"
                            style={{ color: "red" }}
                          >
                            {formik.errors.UnitNumber}
                          </div>
                        )}
                    </div>
                    <div className="input-style">
                      <Form.Label> Postal Code</Form.Label>
                      <Form.Control
                        name="lastname"
                        className="form-control inputLocation4 mt-2 mb-2"
                        placeholder="Enter Your Postal Code"
                        id="postal_code"
                        maxLength="6"
                        {...formik.getFieldProps("postal_code")}
                      ></Form.Control>
                      {formik.touched.postal_code &&
                        formik.errors.postal_code && (
                          <div
                            className="mb-2 warning"
                            style={{ color: "red" }}
                          >
                            {formik.errors.postal_code}
                          </div>
                        )}
                    </div>
                  </>
                ) : null}
              </Row>
            </Container>
            {getUserType() !== 2 && getUserType() !== null ? (
              <>
                <p className="ms-2"> Mark as</p>
                <Container>
                  <Row className="column-gap mb-3">
                    <Col lg="3" md="3" sm="3" xs="3">
                      <Button
                        id="home"
                        onBlur={handleBlur}
                        className={
                          tabValue === "Home"
                            ? "activeclassTabValue "
                            : "office_button"
                        }
                        onClick={() => handleClicked("Home")}
                      >
                        Home
                      </Button>
                    </Col>
                    <Col lg="3" md="3" sm="3" xs="3">
                      <Button
                        id="office"
                        onBlur={handleBlur}
                        className={
                          tabValue === "Office"
                            ? "activeclassTabValue "
                            : "office_button"
                        }
                        onClick={() => handleClicked("Office")}
                      >
                        office
                      </Button>
                    </Col>

                    <Col lg="3" md="3" sm="3" xs="3">
                      <Button
                        id="other"
                        onBlur={handleBlur}
                        className={
                          tabValue === "Other"
                            ? "activeclassTabValue "
                            : "office_button"
                        }
                        onClick={() => handleClicked("Other")}
                      >
                        other
                      </Button>
                    </Col>
                    <p className="mt-2" style={{ color: "red" }}>
                      {markusError}
                    </p>
                  </Row>
                </Container>
              </>
            ) : null}

            <Container>
              <Row>
                <Button
                  className={
                    getUserType() !== 2 && getUserType() !== null
                      ? "confirm_btn locationConfirmBtn"
                      : "confirm_btn locationConfirmBtn  mt-4"
                  }
                  type="submit"
                  onClick={() => {
                    getLocationPopupApi();
                    showmarkUsError();
                  }}
                >
                  Confirm & Proceed
                </Button>
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}
// const mapStateToProps = (state) => {
//   return {
//     loginresponse: state.loginresponse,
//     signupresponse: state.signupresponse,
//     guestresponse: state.guestresponse,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getAddressData: (data) => dispatch(getAddressData(data)),
//     tabValueData: (data) => dispatch(tabValueData(data)),
//   };
// };
// connect(mapStateToProps, mapDispatchToProps)
export default Location;
