import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import RestaurentList from "../containers/restaurent_list/RestaurentList";
import Landing from "../containers/landing/Landing";
import RestaurentView from "../containers/restaurent_view/RestaurentView";
import SearchContainer from "../containers/search/SearchContainer";
import Profile from "../containers/Profile/Profile";
import Payment from "../containers/PaymentandLogin/Payment";
import PaymentReview from "../containers/PaymentandLogin/PaymentReview";
import TermsAndCondition from "../components/Landing/Form/Signup/TermsAndCondition";
import ProtectedRoute from "../containers/app/ProtectedRoute";
import Component2 from "../components/SearchByURL/Component2";
import Component3 from "../components/SearchByURL/Component3";
import { useParams} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import PrivacyPolicy from "../components/Landing/Form/Signup/PrivacyPolicy";
const Routers = () => {
  const restIdentifier = new URLSearchParams(window.location.search);
  const restIdentifierKey = restIdentifier.get("name");

  return (
    <>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/home" element={<RestaurentList />} />
      
      <Route path="/restaurants" element={<RestaurentList />} />

      <Route path="/restaurants/:restIdentifierData" element={<RestaurentView />} />
      <Route path="/search" element={<SearchContainer />} />
      <Route path="/Profile" element={<Profile />} />
      <Route
        path="/payment"
        element={
          <ProtectedRoute>
            <Payment />
         </ProtectedRoute>
        }
      />
      <Route path="/process_payment" element={<PaymentReview />} />
      <Route path="/terms-condition" element={<TermsAndCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Navigate to="/" />} />
      {/* <Route path="/home/:day" element={<Component2 />} />
      <Route path="contact/:dept" element={<Component3 />} /> */}
    </Routes>
    </>
   
  );
};

export default Routers;
