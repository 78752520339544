import { React, useEffect, useRef, useState } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import OTPInput from "otp-input-react";
import * as yup from "yup";
import { useFormik } from "formik";
import swal from "sweetalert";
import "./Login.css";
import { getUserType } from "../../../../constants/Utils";
import {
  getOtpLoginApi,
  LoginVerifyContinueApi,
} from "../../../../services/Landingservice";
function Login({
  getLoginResponse,
  handleCloseFormsPopUp,
  handleOpenLocation,
}) {
  const [timerCount, setTimeCount] = useState(null);
  const [OTP, setOTP] = useState("");
  const [showError, setShowError] = useState("");
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isGetOtp, setIsGetOtp] = useState(false);

  let loginBtnRef = useRef(null);

  useEffect(() => {
    if (isSubmitClicked && OTP && OTP.length < 4) {
      setShowError("Please Enter a valid Otp!");
    } else {
      setShowError();
    }

    if (OTP.length === 4) {
      loginBtnRef.current.focus();
    }
  }, [OTP]);

  let timerOn = true;

  function timer(remaining) {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    let TimerCounter = m + ":" + s;
    remaining -= 1;

    setTimeCount(TimerCounter);

    if (remaining >= 0 && timerOn) {
      setTimeout(function () {
        timer(remaining);
      }, 1000);
      return;
    }

    if (!timerOn) {
      // Do validate stuff here
      return;
    }
  }

  const handleSubmiter = () => {
    setIsSubmitClicked(true);
    if (OTP.length < 4) {
      setShowError("Please Enter a valid Otp!");
    } else {
      setShowError();
      getLoginVerifyApi();
    }
  };

  //SignUp Module GetOtp API :-
  const getLoginOtpApi = async () => {
    let postLoginOtpObj = {
      contact: formik.values.getotpNumber,
      app_id: "2",
    };

    try {
      if (
        Object.keys(formik.errors).length === 0 &&
        Object.keys(formik.touched).length !== 0
      ) {
        let loginApiResponse = await getOtpLoginApi(postLoginOtpObj);
        if (loginApiResponse.data.status === 200) {
          setIsGetOtp(true);
          timer(15);
          if (
            loginApiResponse.data.status === 200 &&
            loginApiResponse.data.data?.is_otp_verified == "1"
          ) {
            swal({
              title: "Success!",
              text: loginApiResponse.data.message,
              type: "success",
              timer: 2500,
              button: false,
              confirmButtonText: "OK",
              className: "popuptetx",
              confirmButtonColor: "#8CD4F5",
              icon: "success",
            });
          }
        } else {
          swal({
            title: "Error!",
            text: loginApiResponse.data.message,
            type: "Error",
            timer: 2500,
            button: false,

            icon: "error",
            className: "popuptetx",
          });
        }
      }
    } catch (e) {}
  };

  //Login Module Login Complete API :-
  const getLoginVerifyApi = async () => {
    let getUser = "";
    if (sessionStorage.getItem("otpResponse")) {
      getUser = JSON.parse(sessionStorage.getItem("otpResponse"))?.id;
    }
    let postLoginVerifyObject = {
      contact: formik.values.getotpNumber,
      code: OTP,
      device_id: "",
      device_type: "",
      device_token: "",
      app_id: "2",
      guest_userId: getUser ? getUser : "",
    };

    try {
      if (
        Object.keys(formik.errors).length === 0 &&
        Object.keys(formik.touched).length !== 0
      ) {
        let loginApiVerifyResponse = await LoginVerifyContinueApi(
          postLoginVerifyObject
        );
        let RestUrl = sessionStorage.getItem("identifier_key")
          ? sessionStorage.getItem("identifier_key")
          : null;

        if (
          window.location.href.includes("/payment") ||
          window.location.href.includes("/home") ||
          window.location.href.includes(`/restaurants/${RestUrl}`)
        ) {
          window.location.reload(true);
        }

        if (loginApiVerifyResponse.data.status === 200) {
          handleCloseFormsPopUp();
          getLoginResponse(loginApiVerifyResponse);
          if (getUserType() === 2) {
            setTimeout(() => {
              handleOpenLocation(true);
            }, 1500);
          }
        } else {
          swal({
            title: "Error!",
            text: loginApiVerifyResponse.data.message,
            type: "Error",
            timer: 2500,
            button: false,
            icon: "error",
            className: "popuptetx",
          });
        }
      }
    } catch (e) {}
  };

  const formik = useFormik({
    initialValues: {
      getotpNumber: "",
    },
    validationSchema: yup.object({
      getotpNumber: yup
        .string()
        .matches(/^[0-9\b]+$/, "Please enter digits only")
        .min(8, "Please enter valid mobile number!")
        .required("Enter your mobile number!"),
    }),
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="loginPopUpLanding">
          <div className="change_text">
            <span>Sign up</span> <b>or log in to your account</b>
          </div>
          <p className="change_text desc-login ">
            <small className="line-two">
              Mobile Numbers of users by sending OTP verification code during
              registaration,login and contact form submissions.
            </small>
          </p>
          <div>
            <div class="LocationInput input-group prefix">
              <span class="input-group-addon">+65</span>
              <input
                type="text"
                className="getMobNumber form-control"
                id="getotpNumber"
                maxLength="8"
                name="getotpNumber"
                placeholder="Phone Number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                {...formik.getFieldProps("getotpNumber")}
              />
            </div>
            {formik.touched.getotpNumber && formik.errors.getotpNumber && (
              <div className="error-style">{formik.errors.getotpNumber}</div>
            )}
          </div>
          <div className="get-otp-btn">
            <Button
              className="getNumber get_otp_button_login"
              type="submit"
              onClick={getLoginOtpApi}
              disabled={
                timerCount !== "00:00" && timerCount !== null ? true : false
              }
            >
              {timerCount === null ? "Get OTP" : "Resend OTP"}
            </Button>
            {isGetOtp ? (
              <>
                <div className="change_text desc-login">
                  <p className="otp loginOtp">Please enter the OTP received</p>
                </div>
                <div className="change_text  desc-login otpformat">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    OTPLength={4}
                    otpType="number"
                    className="otp_inputs_Login otpLoginField"
                    disabled={false}
                  />
                  <div className="" style={{ color: "red", fontSize: "1vw" }}>
                    {showError}
                  </div>
                  {timerCount !== null && timerCount !== "00:00" ? (
                    <div>
                      <small className="TimeLeftText">
                        Time left {timerCount}
                      </small>{" "}
                      <span id="timer"></span>
                    </div>
                  ) : null}
                </div>
                <div className="get-otp-btn">
                  <Button
                    ref={loginBtnRef}
                    className="verify mt-3 ms-4 mb-5"
                    type="submit"
                    onClick={() => {
                      handleSubmiter();
                    }}
                  >
                    Verify
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </Row>
      </Form>
    </>
  );
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getLoginResponse: (data) => dispatch(getLoginResponse(data)),
//   };
// };
// connect(null, mapDispatchToProps)
export default Login;
