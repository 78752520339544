import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { processPaymentThreedsApi } from "../../../services/PaymentService";
import CheckoutForm from "./CheckoutForm";
import "./StripePayment.css";
import { DependencyList, EffectCallback } from "react";

import { stripe_key } from "../../../constants/Utils";
import { stripeKey } from "../../../services/URl/Url";
import { useSelector } from "react-redux";
import { base_url, getOtpLoginApi } from "../../../services/Landingservice";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

export default function StripePayment({
  payAmount,
  cartValue,
  globalCheckoutDetails,
  isWallet,
  handleClose,
  timeSlotFlag,
  cb,
  deps,
  capture,
  setCapture
}) {
  const [clientSecret, setClientSecret] = useState("");
  const [publishableKey, setPublishableKey] = useState("");
  const [responseStatus, setResponseStatus] = useState();
  const [options, setOptions] = useState();
  const appearance = {
    theme: "stripe",
  };
  // let stripePromise= "";
  let stripePromise = loadStripe(publishableKey);
  // const [dataFetchedRef, setDataFetchedRef] = useState(false);
  // const dataFetchedRef = useRef(false);

  const paymentAmountMobile = useSelector(
    (state) => state.counter.payAmounMobile
  );
 
  sessionStorage.setItem("mobile", JSON.stringify(paymentAmountMobile));

  // const getLoginOtpApi = async () => {
  //   let postLoginOtpObj = {
  //     contact: "98989898",
  //     app_id: "2",
  //   };
  //   try {
  //     let baseResponse = await getOtpLoginApi(postLoginOtpObj);
  //   } catch (e) {}
  // };
  const processPaymentThreedsApiValues = async () => {
    let postPaymentDetailsApiObject = {
      total_amount: payAmount,
    };
    // console.log('Biswajit12');
      try {
        let walletDetailsApiResponse = await processPaymentThreedsApi(
          postPaymentDetailsApiObject
        );
        // console.log("clientSecrte",walletDetailsApiResponse,walletDetailsApiResponse.data.data.clientSecret);
        setClientSecret(walletDetailsApiResponse.data.data.clientSecret);
        sessionStorage.setItem(
          "clientStripData",
          JSON.stringify(walletDetailsApiResponse.data.data)
        );
        setPublishableKey(walletDetailsApiResponse.data.data.publishableKey);
        setResponseStatus(walletDetailsApiResponse?.data?.status);
        setOptions({
            clientSecret:walletDetailsApiResponse.data.data.clientSecret,
            appearance,
          });
          // stripePromise =await loadStripe("pi_3P3wERDESgiXS7t718cVlei5_secret_6k26e5KCO6PFgu536dTNGfqop");
      } catch (e) {}
  };

  

  
  // const options = {
  //   clientSecret,
  //   appearance,
  // };
 

  // const [timesRun, setTimesRun] = useState(0);
  // const counter = useRef(0);
  // const effectCalled = useRef(false);
  // let userRes= JSON.parse(sessionStorage.getItem("otpResponse"));
  // let userType= parseInt(userRes.user_type);
  // const [type, setType] = useState(userType);

  useEffect(() => {
    if(capture){
      setCapture(false);
      processPaymentThreedsApiValues();
    }else{
      let stripData=JSON.parse(sessionStorage.getItem("clientStripData"));
      console.log(stripData);
      setClientSecret(stripData.clientSecret);
      setPublishableKey(stripData.publishableKey);
      setResponseStatus(200);
      setOptions({
        clientSecret:stripData.clientSecret,
        appearance,
      });
    }
  }, []);

  // useEffect(() => {
  //   // if(fetchPaymentCapture.current == true){
  //   //   fetchPaymentCapture.current = false;
  //   //   console.log(fetchPaymentCapture.current);
  //   //   console.log('Biswajit');
  //     //processPaymentThreedsApiValues();
  //   //}

  //   if (effectCalled.current) {

  //     return;
  //   } else {
  //     counter.current += 1;

  //     setTimesRun(counter.current);

  //     effectCalled.current = true;
      
  //   }
  // }, []);

  return (
    <div className="App mt-5 mb-3">
      {/* <button onClick={handle}>ok</button> */}
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          {responseStatus === 200 ? (
            <CheckoutForm
              cartValue={cartValue}
              globalCheckoutDetails={globalCheckoutDetails}
              isWallet={isWallet}
              payAmount={payAmount}
              handleClose={handleClose}
              // timeSlotFlag={timeSlotFlag}
            />
          ) : null}
        </Elements>
      ):null}
    </div>
  );
}
